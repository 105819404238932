import {
    Button,
    Grid,
    Typography
}                       from "@mui/material";
import React, {
    useEffect,
    useRef
}                       from "react";
import {
    AddWell,
    AddWellCommand,
    DeactivateWellCommand,
    Delete,
    IGStyle,
    UpdateWell,
    UpdateWellCommand,
    Well,
    WellDetail
} from "../../constants";
import {useGBESContext} from "../../context/GBESContextProvider";
import {submitObject}   from "../../util";

const WellBlock = () =>
{
    const {
        companyDetails,
        selectedCompanyRecord,
        setFormData,
        setDeleteCallback,
        setModalVisible,
        setModalAction, setActivePage, setSubPage, selectedBronRecord,bronDetails,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText, selectedEnergyRecord
    } = useGBESContext()

    //<editor-fold desc="Changeable Variables">
    const AggregateDetails = companyDetails
    const ResourceId = bronDetails.id
    const DSLFilter = ""

    const Title= "Bronnen"
    const RDName = "GBE_WellRD"

    const DetailRefresh = WellDetail

    const AddAction = AddWell
    const UpdateAction = UpdateWell

    const AddButton = "Bron Toevoegen"
    const AddEnabled = false

    const UpdateButton = "Bron Bewerken"
    const UpdateEnabled = true

    const DeleteButton = "Bron Verwijderen"
    const DeleteEnabled = true
    const DeleteCommand = DeactivateWellCommand

    //</editor-fold>

    //<editor-fold desc="Detail - Does not need changing">
    const RDRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case DetailRefresh:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break

        }
        setRefreshResource("")
    }, [refreshResource]);


    function AddObject()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(AddAction)
    }

    function UpdateObject()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateAction)
    }

    function DeleteObject()
    {
        submitObject({id: RDRef.current?.data.id}, AggregateDetails?.id, DeleteCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })
        setActivePage(2)
        setSubPage(0)
        setRefreshResource(DetailRefresh)
    }

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200)
        {
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }
    const handleStartDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        setModalVisible(true)
        setModalAction(Delete)
        setDeleteCallback({delete: DeleteObject} )
    };


    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-detail lang="nl-NL" ref={RDRef} style={IGStyle} class={"ig-detail-remove-rows"} resource-detail-id={RDName} resource-id={ResourceId}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"}  onClick={AddObject}>{AddButton}</Button>}
                            {UpdateEnabled && <Button variant={"contained"}  onClick={UpdateObject}>{UpdateButton}</Button>}
                            {DeleteEnabled && <Button variant={"contained"}  onClick={handleStartDelete}>{DeleteButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
    //</editor-fold>
}

export default WellBlock