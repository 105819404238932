import {
    Alert,
    Button,
    Grid
} from "@mui/material";
import React, {useEffect} from "react";
import {
    AuthToken,
    baseUrl
} from "../constants";
import {useGBESContext} from "../context/GBESContextProvider";
import UpsertObjectModal from "../modals/UpsertObjectModal";
import BronBoorOverview from "./BronBoorOverview";
import ControlesOverview from "./ControlesOverview";
import LegalEntityDetailOverview from "./LegalEntityDetailOverview";
import LegalEntityMeasurementOverview from "./LegalEntityMeasurementOverview";
import LegalEntityOverview from "./LegalEntityOverview";
import NotitiesOverview from "./NotitiesOverview";
import SysteemGegevensOverview from "./SysteemGegevensOverview";

const MainPage = () => {

    const {
        activePage, setActivePage,
        subPage, setSubPage,
        alertVisible, alertText,
        setAlertVisible,
    } = useGBESContext()

    useEffect(() => {
        setAlertVisible(false)
    }, [subPage, activePage]);

    function onClickHome() {
        setActivePage(0)
    }

    function onClickBack() {
        //Decrement it by one to go back a page, disspears when activepage is 0
        if (activePage > 0) {
            setActivePage((activePage: number) => activePage - 1)
        }
    }

    const TopButtons = <Grid container justifyContent="center">
        <Grid item>
            <Grid item>
                <Button variant={"contained"} className={"default-button"} onClick={onClickHome}>Home</Button>
                {activePage !== 0 &&
                    <Button variant={"contained"} className={"default-button"} onClick={onClickBack}>Terug</Button>}
                <Alert style={{
                    display: alertVisible
                        ? "flex"
                        : "none",
                }} severity="error" color={"warning"}>{alertText}</Alert>

            </Grid>
        </Grid>
    </Grid>


    const SelectionButtonsPage2 = <Grid container className={"rm-container"} justifyContent={"flex-start"}
                                        flexDirection={"row"}>
        <Button onClick={() => setSubPage(0)} variant={subPage === 0
            ? "outlined"
            : "text"}>Systeemgegevens</Button>
        <Button onClick={() => setSubPage(1)} variant={subPage === 1
            ? "outlined"
            : "text"}>Controles</Button>
        <Button onClick={() => setSubPage(2)} variant={subPage === 2
            ? "outlined"
            : "text"}>Notities</Button>
    </Grid>

    const SelectionButtonsPage1 = <Grid container className={"rm-container"} justifyContent={"flex-start"}
                                        flexDirection={"row"}>
        <Button onClick={() => setSubPage(0)} variant={subPage === 0
            ? "outlined"
            : "text"}>Legale Entiteit Gegevens</Button>
        <div style={{paddingTop: 4}}>|</div>
        <Button onClick={() => setSubPage(1)} variant={subPage === 1
            ? "outlined"
            : "text"}>Meetgegevens</Button>
    </Grid>

    return (<>
        <html ig-base-url={baseUrl + "/ig"} ig-api-token={AuthToken}>
        <p>GBES</p>
        <Grid container direction={"column"}>
            {TopButtons}

            <Grid className={"rm-container"}>

                {activePage === 0 && <LegalEntityOverview/>}

                {activePage === 1 && SelectionButtonsPage1}
                {activePage === 1 && subPage === 0 && <LegalEntityDetailOverview/>}
                {activePage === 1 && subPage === 1 && <LegalEntityMeasurementOverview/>}

                {activePage === 2 && SelectionButtonsPage2}
                {activePage === 2 && subPage === 0 && <SysteemGegevensOverview/>}
                {activePage === 2 && subPage === 1 && <ControlesOverview/>}
                {activePage === 2 && subPage === 2 && <NotitiesOverview/>}

                {activePage === 3 && subPage === 0 && <BronBoorOverview/>}

            </Grid>
            <UpsertObjectModal/>

        </Grid>
        </html>

    </>)
}
export default MainPage