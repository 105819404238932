import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import React, {
    useEffect,
    useRef
}                      from "react";
import {
    AddLegalEntity,
    LegalEntity,
    IGStyle,
}                       from "../../constants";
import {useGBESContext} from "../../context/GBESContextProvider";
import {
    getCompanyRecordSystem,
}                       from "../../util";

const SearchableLegalEntityBlock = () =>
{
    const {
        activePage, setActivePage,
        setCompanyDetails, setSubPage,
        setFormData,setSelectedCompanyRecord,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource,
    } = useGBESContext()
    //<editor-fold desc="Changeable Variables">

    const Title= "Legale Entiteit"
    const RMName = "GBE_SearchableLegalEntityRM"

    const MasterRefresh = LegalEntity
    const AddAction = AddLegalEntity
    const AddButton = "Legale Entiteit Toevoegen"
    const AddEnabled = true

    const DSLFilter = ""

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            getCompanyRecordSystem(event.detail.recordId).then(async (data: any) =>
                {
                    const Data = await data.json()
                    setActivePage(1)
                    setSubPage(0)
                    setCompanyDetails(Data)
                    setSelectedCompanyRecord(`aggregateInstanceId =="` + event.detail.recordId + "\"")
                }
            )
        })
    }, [])

    //</editor-fold>

    //<editor-fold desc="Searchable Master - Does not need changing">
    const RMRef = useRef<any>()


    useEffect(() =>
    {
        console.log("doing master refresh",refreshResource)
        switch (refreshResource)
        {
            case MasterRefresh:
                setTimeout(() => RMRef.current?.refresh(), 1500)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddObject()
    {
        setFormData(undefined)
        setCompanyDetails(undefined)

        setModalVisible(true)
        setModalAction(AddAction)
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" style={IGStyle} ref={RMRef} class={"ig-columns"} searchable-resource-master-id={RMName}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"} onClick={AddObject}>{AddButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
    //</editor-fold>

}

export default SearchableLegalEntityBlock